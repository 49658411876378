export const IconHome = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
		<g fill="none" fillRule="evenodd">
			<path
				d="M6 17.942h3.346v-5.038c0-.256.087-.47.26-.644a.875.875 0 0 1 .644-.26h3.5c.256 0 .47.087.644.26.173.173.26.388.26.644v5.038H18V9.096a.29.29 0 0 0-.034-.14.375.375 0 0 0-.091-.11l-5.692-4.279A.268.268 0 0 0 12 4.5a.268.268 0 0 0-.183.067l-5.692 4.28a.375.375 0 0 0-.091.11.29.29 0 0 0-.034.14v8.845zm-1.5 0V9.096a1.795 1.795 0 0 1 .723-1.446l5.692-4.288A1.738 1.738 0 0 1 11.998 3c.407 0 .769.12 1.087.362l5.692 4.288c.226.166.403.377.53.633.129.256.193.527.193.813v8.846c0 .41-.148.761-.443 1.057a1.443 1.443 0 0 1-1.057.443h-3.942a.875.875 0 0 1-.644-.26.875.875 0 0 1-.26-.644V13.5h-2.308v5.038c0 .256-.086.471-.26.644a.875.875 0 0 1-.644.26H6c-.409 0-.761-.148-1.057-.443a1.443 1.443 0 0 1-.443-1.057z"
				fill="#7C839E"
				fillRule="nonzero"
			/>
		</g>
	</svg>
);
