import Icon from '@mdi/react';
import { Box } from '@mui/material';
import { Link, generatePath, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { mdiCircleSmall, mdiForumOutline, mdiTruckOutline } from '@mdi/js';

import { Actions } from '@heylog-app/shared/types';

import {
	StyledDotIcon,
	StyledMenuText,
	StyledNavItem,
	StyledNavList,
	StyledUnreadCount,
} from './sidebar-v2.styles';
import { colors } from '../../styles';
import { matchNavigationPath } from '../../util/match-navigation-path';
import { ROUTES, getUnreadSum } from '../../util';
import { useActiveUnread, usePlugins } from '../../hooks';
import { Can } from '../can';
import { IconClipboard } from '../icons/icon-clipboard';
import { IconFolders } from '../icons/icon-folders';
import { IconHome } from '../icons/icon-home';

import type { FC, PropsWithChildren } from 'react';

interface SidebarV2PropsInterface {
	width: string;
	isOpened: boolean;
}

export const SidebarV2: FC<PropsWithChildren<SidebarV2PropsInterface>> = ({
	width,
	isOpened,
}) => {
	const location = useLocation();
	const { pathname } = location;
	const { t } = useTranslation();
	const params = useParams();
	const { workspaceId = '' } = params;
	const { activeUnread } = useActiveUnread();
	const unreadCount = getUnreadSum(activeUnread);
	const hasContactsUnread = unreadCount > 0;
	const { FleetCheckPlugin, FleetCheckReportPlugin, FrontendV2Plugin } =
		usePlugins(workspaceId);

	const styles = {
		minWidth: width,
		width,
		borderRight: `1px solid ${colors.grey[300]}`,
		transition: '.4s all ease',
	};

	const ClosedMenuUnredDot = (
		<StyledDotIcon data-test="unread-contacts-dot" path={mdiCircleSmall} size={1} />
	);

	const OpenedMenuUnredCount = (
		<StyledUnreadCount>{unreadCount > 99 ? '99+' : unreadCount}</StyledUnreadCount>
	);

	const UnreadIndicator = isOpened ? OpenedMenuUnredCount : ClosedMenuUnredDot;

	return (
		<Box sx={styles}>
			<StyledNavList>
				<StyledNavItem
					role="navigation"
					data-test="dashboard-tab-button"
					$active={matchNavigationPath(pathname, {
						...ROUTES.DASHBOARD_MAIN,
					})}
				>
					<Link to={generatePath(ROUTES.DASHBOARD_MAIN.OVERVIEW, params)}>
						<IconHome />
						<StyledMenuText>{t('navigation.dashboard')}</StyledMenuText>
					</Link>
				</StyledNavItem>

				<StyledNavItem
					role="navigation"
					data-test="orders-tab-button"
					$active={matchNavigationPath(pathname, {
						...ROUTES.DASHBOARD,
						...ROUTES.SHIPMENTS,
					})}
				>
					<Link to={generatePath(ROUTES.DASHBOARD.OVERVIEW, params)}>
						<IconClipboard />
						<StyledMenuText>{t('navigation.orders')}</StyledMenuText>
					</Link>
				</StyledNavItem>

				<StyledNavItem
					role="navigation"
					data-test="contacts-tab-button"
					$active={matchNavigationPath(pathname, ROUTES.MESSAGES)}
				>
					<Link to={generatePath(ROUTES.MESSAGES.OVERVIEW, params)}>
						<Icon path={mdiForumOutline} size={1} />
						<StyledMenuText>{t('navigation.messages')}</StyledMenuText>
						{hasContactsUnread && UnreadIndicator}
					</Link>
				</StyledNavItem>
				{FleetCheckPlugin && FleetCheckReportPlugin && (
					<Can I={Actions.MANAGE} a="Workspace">
						{() => (
							<StyledNavItem
								role="navigation"
								data-test="fleet-nav-menu"
								$active={matchNavigationPath(pathname, ROUTES.FLEET)}
							>
								<Link to={generatePath(ROUTES.FLEET.OVERVIEW, params)}>
									<Icon path={mdiTruckOutline} size={1} />
									<StyledMenuText>{t('navigation.fleet')}</StyledMenuText>
								</Link>
							</StyledNavItem>
						)}
					</Can>
				)}
				{FrontendV2Plugin && (
					<StyledNavItem
						role="navigation"
						data-test="documents-nav-menu"
						$active={matchNavigationPath(pathname, ROUTES.DOCUMENTS)}
					>
						<Link to={generatePath(ROUTES.DOCUMENTS.OVERVIEW, params)}>
							<IconFolders />
							<StyledMenuText>{t('navigation.documents')}</StyledMenuText>
						</Link>
					</StyledNavItem>
				)}
			</StyledNavList>
		</Box>
	);
};
